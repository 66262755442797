import { Controller } from "stimulus";

export default class extends Controller {
  initialize() {
    this.$element = $(this.element);

    this.$element.on(
      "ajax:success",
      '[data-behavior="installment-done"]',
      event => {
        this.removeRow(event);
      }
    );

    this.$element.on(
      "ajax:success",
      '[data-behavior="installment-group-done"]',
      event => {
        this.removeGroup(event);
      }
    );
  }

  removeGroup(event) {
    const $element = $(event.target);
    const $group = $element.parents('[data-behavior="conciliation-group"]');
    $group.remove();
  }

  removeRow(event) {
    const $element = $(event.target);
    const $parent = $element.parents('[data-behavior="installment-row"]');
    const $group = $element.parents('[data-behavior="conciliation-group"]');
    const $siblings = $parent.siblings();

    $parent.remove();

    if ($siblings.length == 0) {
      $group.remove();
    }
  }
}

import { Controller } from "stimulus";
import { reload } from "../util/routes";

export default class extends Controller {
  initialize() {
    this.$element = $(this.element);
    this.$element.dialog({
      modal: true,
      minWidth: 600,
      closeText: "",
      close: () => this.destroy()
    });
  }

  destroy() {
    this.$element.dialog("destroy").remove();

    if (!this.skipReload()) {
      reload();
    }
  }

  skipReload() {
    return this.data.get("skipReload");
  }
}

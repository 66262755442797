import { Controller } from "stimulus";

import { redirectToParams } from "../util/routes";

export default class extends Controller {
  changeFilter(event) {
    event.preventDefault();

    const { filterName, filterValue } = event.target.dataset;
    const params = this.filterParams;
    const filterValues = params[filterName] || [];
    const valueIndex = filterValues.indexOf(filterValue);

    if (valueIndex >= 0) {
      filterValues.splice(valueIndex, 1);
    } else {
      filterValues.push(filterValue);
    }

    const urlParams = Object.assign(params, { [filterName]: filterValues });

    redirectToParams(urlParams);
  }

  get filterParams() {
    return JSON.parse(this.data.get("params"));
  }
}

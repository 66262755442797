import { Controller } from "stimulus";

import serialize from "../util/serialize";

export default class extends Controller {
  static targets = ["preview"];

  updatePreview() {
    const data = serialize(this.element);
    const url = this.url;

    Rails.ajax({
      type: "POST",
      url,
      data,
      success: (_resp, _status, xhr) => this.renderPreview(xhr.responseText)
    });
  }

  renderPreview(content) {
    this.previewTarget.innerHTML = content;
  }

  get url() {
    return this.data.get("url");
  }
}

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "./theme.scss";

import "jquery";
import "jquery-ui/ui/widgets/dialog";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/i18n/datepicker-pt-BR";
import "bootstrap-sass/assets/javascripts/bootstrap/alert";
import "bootstrap-sass/assets/javascripts/bootstrap/collapse";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown";
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip";

import Rails from "rails-ujs";
import Turbolinks from "turbolinks";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

window.Rails = Rails;
window.$ = window.jQuery = jQuery;

Rails.start();
Turbolinks.start();

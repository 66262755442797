import { Controller } from "stimulus";

import serialize from "../util/serialize";
import { redirect } from "../util/routes";

export default class extends Controller {
  submit(e) {
    e.preventDefault();

    const form = this.element;

    const url = form.action;
    const params = serialize(form);

    redirect(`${url}&${params}`);
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["operations", "installments"];

  initialize() {
    this.createInstallments();
  }

  createInstallments() {
    const { installmentsTarget, installments } = this;
    const currentInstallment = this.currentInstallment;
    const options = [];

    for (let i = 0; i < installments; i++) {
      const number = i + 1;
      const selected = number === currentInstallment ? "selected" : "";
      const option = `<option value="${number}" ${selected}>${number}</option>`;

      options.push(option);
    }

    installmentsTarget.innerHTML = options.join("");
  }

  get currentInstallment() {
    const { value } = this.installmentsTarget.dataset;
    return parseInt(value);
  }

  get installments() {
    const { options, selectedIndex } = this.operationsTarget;
    const { installments } = options[selectedIndex].dataset;

    return installments;
  }
}

const defaultOptions = {
  showButtonPanel: true
};

export default class {
  constructor(element) {
    const options = Object.assign(defaultOptions, {
      onSelect: (date, _datePicker) => this.notify(date)
    });

    this.$element = $(element);
    this.$element.datepicker(options);
    this.hooks = [];
  }

  setDate(date) {
    this.$element.datepicker("setDate", date);

    return this;
  }

  onSelect(callback) {
    this.hooks.push(callback);

    return this;
  }

  notify(date) {
    this.hooks.forEach(callback => {
      if (typeof callback === "function") {
        callback(date);
      }
    });
  }
}

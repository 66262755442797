import Turbolinks from "turbolinks";

import encodeParams from "../util/encode-params";

export const redirect = url => {
  return Turbolinks.visit(url);
};

export const reload = () => redirect(window.location);

export const redirectToParams = params => {
  const encodedParams = encodeParams(params);
  const { pathname } = window.location;

  return redirect(`${pathname}?${encodedParams}`);
};

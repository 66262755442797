import { Controller } from "stimulus";

import money from "../util/money";

export default class extends Controller {
  formatValue() {
    const { element } = this;
    const { value } = element;

    element.value = money(value);
  }
}

import { Controller } from "stimulus";

import DatePicker from "../util/date-picker";
import { redirectToParams } from "../util/routes";

export default class extends Controller {
  initialize() {
    new DatePicker(this.element)
      .setDate(this.currentDate)
      .onSelect(date => this.dateChanged(date));
  }

  dateChanged(date) {
    const params = Object.assign(this.urlParams, { [this.fieldName]: date });

    redirectToParams(params);
  }

  get currentDate() {
    return this.data.get("date");
  }

  get fieldName() {
    return this.data.get("fieldName");
  }

  get urlParams() {
    return JSON.parse(this.data.get("urlParams"));
  }
}

import accounting from "accounting";

accounting.settings.number = {
  precision: 2,
  thousand: ".",
  decimal: ","
};

export default value => {
  const formatted = accounting.formatNumber(value);

  return `R$ ${formatted}`;
};

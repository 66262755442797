import { Controller } from "stimulus";

const OPEN_CLASS = "open";
const HIDE_CLASS = "hide";
const HIDE_EVENT = "hidden.bs.dropdown";

const addClass = (el, clazz) => el.classList.add(clazz);

const removeClass = (el, clazz) => el.classList.remove(clazz);

export default class extends Controller {
  static targets = ["form", "sibling"];

  initialize() {
    $(this.element).on(HIDE_EVENT, () => this.hide());
  }

  show(e) {
    e.stopPropagation();

    addClass(this.element, OPEN_CLASS);
    removeClass(this.formTarget, HIDE_CLASS);

    this.siblingTargets.forEach(el => addClass(el, HIDE_CLASS));
  }

  hide() {
    removeClass(this.element, OPEN_CLASS);
    addClass(this.formTarget, HIDE_CLASS);

    this.siblingTargets.forEach(el => removeClass(el, HIDE_CLASS));
  }
}
